@import url(https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400;500;600;700;800&family=Open+Sans&display=swap);
/* Document
 * ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */

html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
 * ========================================================================== */

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */

textarea {
  margin: 0; /* 1 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/* User interaction
 * ========================================================================== */

.home-logo {
    padding: 7% 0;
    width: 30%;
  }
 
  #date {
    float:left;
    margin-left:-350px;
  }
 
/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: #5a5a5a;
  }
  
  
  /* CUSTOMIZE THE CAROUSEL
  -------------------------------------------------- */
  
  /* Carousel base class */
  .carousel {
    margin-bottom: 4rem;
  }
  /* Since positioning the image, we need to help out the caption */
  .carousel-caption {
    bottom: 3rem;
    z-index: 10;
  }
  
  /* Declare heights because of positioning of img element */
  .carousel-item {
    height: 32rem;
    background-color: #777;
  }
  .carousel-item > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 32rem;
  }
  
  
  /* MARKETING CONTENT
  -------------------------------------------------- */
  
  /* Center align the text within the three columns below the carousel */
  .marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  .marketing h2 {
    font-weight: 400;
  }
  .marketing .col-lg-4 p {
    margin-right: .75rem;
    margin-left: .75rem;
  }
  
  
  /* Featurettes
  ------------------------- */
  
  .featurette-divider {
    margin: 5rem 0; /* Space out the Bootstrap <hr> more */
  }
  
  /* Thin out the marketing headings */
  .featurette-heading {
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.05rem;
  }
  
  
  /* RESPONSIVE CSS
  -------------------------------------------------- */
  
  @media (min-width: 40em) {
    /* Bump up size of carousel content */
    .carousel-caption p {
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.4;
    }
  
    .featurette-heading {
      font-size: 50px;
    }
  }
  
  @media (min-width: 62em) {
    .featurette-heading {
      margin-top: 7rem;
    }
  }
  
/* body {
  font-size: .875rem;
} */

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
  padding-top: 48px; /* Space for fixed navbar */
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.MuiListItem-gutters {
  padding-left: 8px !important;
  padding-right: 16px;
}

.img-cropper {
  width: 100px;
  height: 100px;
  margin: auto;
  overflow: hidden;
  border-radius: 50%;
}
.img-d-cropper {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}
.profile-avatar {
  height: 100%;
}
.buttonDelete {
  color: #f22828 !important;
  /* background-color: #f22828 !important; */
}

/* a:not([href]):hover {
  text-decoration: inherit;
} */
.img-cropper-profile {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 60px;
  height: 60px;
  margin: auto;
  overflow: hidden;
  border-radius: 50%;
}

.label-avatar {
  color: midnightblue;
  font-weight: bold;
}

.label-form {
  color: lightslategray;
}

.MuiList-root > a:hover {
  text-decoration: none;
}
.container-card-role {
  height: 70vh;
}

.container-card-inner-role {
  min-height: 50vh;
}

.MuiDrawer-paper {
  background-color: #212529 !important;
}
/* 
.MuiDrawer-paper.MuiPaper-root,
.MuiListItemIcon-root {
  color: antiquewhite !important;
} */
.MuiList-root > a,
.MuiListItemIcon-root {
  color: #d9eef7 !important;
  /* text-decoration: none; */
}

.makeStyles-paper-12 {
  height: 450px !important;
}

.MuiCardHeader-content > .MuiTypography-h5 {
  font-size: 0.9rem;
}

.MuiDivider-root {
  background-color: #343a40 !important;
}

.h1-section-title {
  font-size: 2rem;
  color: #596067;
}

.span-section-title {
  font-size: 1rem;
}

.title-app-review {
  color: lightslategray;
}

.image-profile {
  top: -4px !important;
}
.border-green {
  border: 1px solid #28a745 !important;
}

.w-40-custom {
  width: 40% !important;
}

.w-30-custom {
  width: 30% !important;
}

.w-20-custom {
  width: 20% !important;
}

.w-15-custom {
  width: 15% !important;
}

.w-10-custom {
  width: 10%;
}
.title-activate-user {
  font-weight: bold !important;
  color: grey;
}

.fieldset-status {
  padding: 10px;
  box-shadow: 2px 2px 11px 0px lightgrey;
  border-radius: 5px;
}

/* .makeStyles-drawerClose-24 {
    width: 48px !important;
}


.makeStyles-drawerClose-25 {
    width: 48px !important;
} */

.divider {
  height: auto;
  max-height: 200px;
  width: 8px;
  background-color: #1089e299; /* #dcddde73*/
  margin: 5px;
  border-radius: 8px;
  border: none;
}

.divider-main {
  height: 160px;
  max-height: 160px;
  width: 4px;
  background-color: #1089e299; /* #dcddde73*/
  margin: 5px;
  border-radius: 8px;
  border: none;
}

.dividerAppbar {
  height: 40px;
  max-height: 40px;
  width: 2px;
  background-color: #0c436a9e; /* #dcddde73*/
  margin: 10px;
  margin-right: 15px;
  border-radius: 8px;
  border: none;
}
/* .menuAppbar {
  color: white !important;
} */

.rounded-lg-custom {
  border-radius: 1.3rem !important;
}

.rounded-sm-custom {
  border-radius: 0.3rem !important;
}

.rounded-md-custom {
  border-radius: 1rem !important;
}

.sunshine-icon {
  width: 5%;
  margin-bottom: 5px;
}
.avatar-custom {
  border: 2px solid #085279a1;
  background-color: black;
  width: "50px";
  height: "50px";
  align-items: center !important;
}

.avatar-rate {
  border: 2px solid #085279a1;
  background-color: black;
  width: "100px !important";
  height: "100px !important";
}

.search-panel {
  background-color: #e3e9ec !important;
}

.appt-card {
  max-width: auto;
}

.font-appt-date {
  font-weight: 900;
  font-size: large;
}

.sub-container-widget {
  height: 500px;
  /* border: 1px solid blue; */
}

.background-root {
  background-color: #e5e5e5;
}

.makeStyles-root-4 {
  background-color: #e5e5e5;
}
/* .experience {
  font-size: 20px;
} */

.DivWithScroll {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}
.box-content {
  height: 200px;
  width: 250px;
  overflow: auto;
  border-radius: 8px;
}
.text-label {
  font-size: medium;
  font-weight: bold;
}
.text-span-value {
  font-size: medium;
  font-weight: bold;
  color: #085279;
}
.container-appt-summary {
  margin: 10px;
  padding: 10px;
}

.divider-dashboard-appt {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    #a3c0de,
    rgba(0, 0, 0, 0)
  );
}

.title-dashboard-card {
  font-size: 20px;
  font-weight: bold;
  color: #a9bdc3;
}
.title-custome {
  font-size: 20px;
  font-weight: bold;
  color: #afafaf;
}
.flag-size {
  width: 30px;
}

.issued {
  border: 1px solid rgb(209, 209, 209);
  border-radius: 5px;
  width: 100%;
  height: 100%;
}
.selectOverflow {
  overflow-x: scroll !important;
}

.checkout-resume-table {
  border: 2px solid #e0eaf5;
  margin: 10px;
  border-radius: 8px;
}

.prescription-card {
  width: 700px;
  height: 250px;
}

.dependants-card {
  width: 700px;
  height: 260px;
  max-height: 260px;
}

.h-60-custom {
  height: 60px !important;
}

.h-80-custom {
  height: 80px !important;
}
.fc .fc-highlight {
  background: #55ad27a6 !important;
}

.text-instructions {
  font-size: 15px;
  font-family: system-ui;
  color: #294ea2;
  font-weight: 500;
}

.paymentIcon-completed {
  font-size: 20px;
  color: green;
  margin-left: 5px;
}

.paymentIcon-await {
  font-size: 20px;
  color: rgb(42, 54, 68);
  margin-left: 5px;
}

.box-hour {
  width: 100px;
  border-radius: 8px;
  border: 2px solid var(--blue);
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  margin: 2px;
}

.box-hour:hover {
  background-color: var(--blue);
  color: white;
  transition: box-shadow 0.3s ease 0s;
}

.box-hour-selected {
  background-color: var(--blue);
  color: white;
}

.fieldset {
  position: relative;
  border: 1px solid #ddd;
  padding: 10px;
}

.fieldset h3 {
  position: absolute;
  top: 0;
  font-size: 14px;
  font-weight: bold;
  color: #085279;
  line-height: 1;
  margin: -9px 0 0; /* half of font-size */
  background: whitesmoke;
  padding: 0 3px;
}

.timeline-message-container {
  overflow-y: scroll;
  height: 40%;
}

.label-read-btn {
  font-size: 15px;
  font-weight: bolder;
  margin-right: 5px;
  color: #085279;
}

.video-container {
  width: 70% !important;
}

.input-condition-detail {
  border-radius: 8px;
  border-color: lightgray;
}

.textResponseOnSave {
  text-align: center;
  font-size: 18px;
  margin: 8px;
  color: brown !important;
}

.fadeout {
  color: #f5efef;
  font-size: large;
  font-family: helvetica;
  text-align: center;
  opacity: 0;
  -webkit-animation: fadeout 4s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadeout 4s;
}

@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.timer-container {
  position: relative;
  display: flex;
  flex: 0 1 25%;
  font-size: 20px;
  background-color: #404549;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
}

.dots-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  margin: 4px 2px 10px 2px;
}

.dots-timer {
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #404549;
  border-radius: 6px;
  margin: 5px 0px;
}

.timer-parent-container {
  width: 40px;
  max-width: 40px;
}

.paypal-buttons {
  max-width: 600px;
}

.specialty-chip {
  color: white;
  background-color: #03a9f4;
}

.btn-login-custom {
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  background-color: #2c7cf3;
  color: #fff;
  font-weight: 500;
}

.bg-gray-custom {
  background-color: #f9fafb !important;
}

.title-admin-form {
  font-size: 2rem;
}

.login-side-ads {
  width: 100%;
  height: 100%;
  background-image: url("https://res.cloudinary.com/brackets-it/image/upload/w_1200,h_800/v1627784016/wallpaper-doctor_p1wsvc.jpg");
  background-repeat: no-repeat;
}

.text-red-900 {
  color: rgb(216, 101, 101);
}

.card-higthlights {
  height: 300px !important;
  max-height: 300px;
}

.avatar-company-custom {
  border: 5px solid #0a8ed4;
  background-color: black;
  width: 150px !important;
  height: 150px !important;
  align-items: center !important;
}

.custom-cancel-button {
  background-color: #e0e0e0;
}

.MuiListSubheader-colorPrimary {
  background-color: #212529 !important;
}

.appt-type-icon {
  display: grid;
  float: left;
}

.appt-indicator-icon {
  color: #21cdbd;
  font-size: 2rem !important;
}
.appt-type-label {
  font-size: 0.5rem;
}

.card-header .header-card-user {
  padding: 0px;
}

.divider-appt-op{
  /* border: 0; */
  height: 1px;
  background-color: #b8bbc5 !important;
  width: 70%;
  
}

.MuiAccordionDetails-root{
  min-height: 280px;
}

.w-vw-100 {
  width: 100vw;
}

.dropzone{
  border: 2px dashed #0087F7;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin: 10px;
}

@media only screen and (max-device-width: 768px) {
  /* For ipad/tablets phones: */
  .login-side-ads {
    display: none !important;
  }
}

.card-doctor-summary {
  font-size: 14px;
  font-weight: bold;
}

/* Order Card */
.card_header_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chip-card-label {
  font-size: 10px;
}

.w-full {
  width: 100%;
}

.mb-10-custom {
  margin-bottom: 10%;
}

.card_description {
  color: lightslategray;
  text-align: justify;
}

.row-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.m-10-custom {
  margin-left: 10px;
}

.fontS-2 {
  font-size: 13px;
}

.mw-160 {
  max-width: 160px !important;
}

[id*="jitsiMeeting-"] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.alertTermsUse {
    background-color: #bcd7f5;
    overflow-y: scroll;
    height: 100px;
}
.cursor {
    cursor: pointer;
}
a:not([href]) {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    text-decoration: inherit;
    color: #257adb;
    cursor: pointer;
}
p{
    margin: 10px;
}
.swal2-content {
    z-index: 1;
    justify-content: center;
    margin: 0;
    padding: 0;
    color: #545454;
    font-size: 1.125em;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    word-wrap: break-word;
    overflow-y: scroll;
    height: 500px;
}
.swal2-popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    width: 36em !important;
    max-width: 100%;
    padding: 1.25em;
    border: none;
    border-radius: .3125em;
    background: #fff;
    font-family: inherit;
    font-size: 1rem;
}
p label{
    text-decoration: inherit;
    color: #257adb;
    cursor: pointer;
}
.text-muted {
  color: #212529 !important;
  text-align: center;
}

.hospital {
  width: 500px;
  height: 350px;
}

@media only screen and (max-width: 450px) {
  /* For mobile phones: */
  .hospital {
    width: 300px;
    height: 220px;
    padding-top: 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* For ipad/tablets phones: */
  .hospital {
    width: 550px;
    height: 450px;
    padding-top: 50px;
  }
}

.lang-buttons-container {
  align-items: center;
}

.lang-text {
  color: black;
}

.btn-toggle-login {
  background-color: #00b4d885;
}

.btn-toggle-login:hover {
  background-color: #0077b6;
  color: aliceblue;
}

.btn-toggle-register {
  background-color: #a8dadc;
}
.btn-toggle-register:hover {
  background-color: #0077b6;
  color: aliceblue;
}

.root-providers {
  height: 100%;
  background-image: url(/static/media/providers-wallpaper.1c45ee79.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
body {
  background-color: #e4e4e4 !important;
  padding: 1rem;
}
.text-muted {
  color: #212529 !important;
  text-align: center;
}
.MuiAvatar-img {
  width: auto !important;
}

.hospital {
  width: 500px;
  height: 350px;
}

.icon-login-container {
  width: 100px;
  display: flex;
  justify-content: center;
}
.icon-login-avatar {
  background-color: transparent;
  width: 2000px !important;
  height: 150px !important;
  border-radius: 0;
  margin-bottom: 30px;
  padding: 10px;
}

@media only screen and (max-width: 450px) {
  /* For mobile phones: */
  .hospital {
    width: 300px;
    height: 220px;
    padding-top: 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* For ipad/tablets phones: */
  .hospital {
    width: 550px;
    height: 450px;
    padding-top: 50px;
  }
}

.maincontainer {
  padding: 0rem 3rem 0rem 3rem;
}

.button {
  padding: 10px 45px 30px 0px;
}

@media only screen and (max-width: 450px) {
  /* For mobile phones: */
  .button {
    padding: 15px 0px 15px 0px;
  }
  .maincontainer {
    padding: 0px;
    width: 360px;
  }
  .fc {
    width: 360px;
    height: 600px;
    padding-top: 20px;
  }
  .fc .fc-toolbar {
    justify-content: space-around;
    padding-bottom: 20px;
  }
  .fc-header-toolbar {
    height: 100px;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .fc-button-group {
    padding: 10px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* For ipad/tablets phones: */
  .fc {
    width: 650px;
    height: 700px;
    padding-top: 50px;
  }
}

.MuiAutocomplete-root{
    width: 100% !important;
}
.cost{
    transition:  1s;
}

.lisItem:hover{
    background-color: #dfdfdf !important;
}
.hvr-grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    border-radius: 10px;
    margin: 0.7em;
    box-shadow: 0px 3px 1px #999;
   
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

}
.hvr-grow img{
    width: 180px;
    height: 120px;
}
